<latch-loading-bar
  [isActive]="isSubmitting"
  background="transparent"
  class="loading-bar"
></latch-loading-bar>

<img src="shared/assets/images/latch-logo.svg" alt="logo" class="logo" />
<h2>Deactivate Keycard</h2>

<form 
  #form="ngForm" 
  (ngSubmit)="handleSubmitPreCaptcha($event) && captchaRef.execute()"
  *ngIf="currentStep === step.EnterDetails"
>
  <div class="form-group" [class.has-error]="sendEmailError === SendEmailError.InvalidEmailFormat">
    <label class="form-control-label">Enter your Latch account email</label>
    <input
      type="email"
      class="form-control"
      name="email"
      autocomplete="off"
      [(ngModel)]="email"
      [disabled]="isSubmitting"
      latchAutofocus
      email
      required
      #emailEl
    />
    <div class="form-control-feedback" [class.invisible]="sendEmailError !== SendEmailError.InvalidEmailFormat">
      <ng-container *ngIf="sendEmailError === SendEmailError.InvalidEmailFormat">
          Enter a valid email
      </ng-container>
    </div>
  </div>
  <div class="text-danger overall" *ngIf="sendEmailError === SendEmailError.InvalidSerialNumberOrEmail">
    We couldn't find a Keycard associated with that email.
  </div>
  <div class="actions">
    <button type="submit" class="button button-upsell">Next</button>
  </div>
  <div class="captcha">
    <re-captcha
        #captchaRef="reCaptcha"
        [(ngModel)]="captcha"
        name="captcha"
        badge="inline"
        size="invisible"
        (resolved)="$event && handleSubmitPostCaptcha()"
    ></re-captcha>
  </div>
</form>

<div *ngIf="currentStep === step.Confirmation">
  <h3>We've sent a deactivation email</h3>
  <p>Open the link in the email to deactivate your Latch Keycard.</p>
  <div class="resend-email">
    <button
      class="button button-outline-secondary"
      (click)="captchaRef.execute()"
      [disabled]="isResendingEmail"
    >
      Resend email
    </button>
    <div class="status" *ngIf="isResendingEmail">
      <div class="indicator spinner-ring"></div> Resending...
    </div>
    <div class="status" *ngIf="resendEmailSuccess">
      <i class="indicator icon-check"></i> Re-sent
    </div>
    <div class="status text-danger" *ngIf="resendEmailError">
      {{resendEmailError}}
    </div>
  </div>
  <div class="captcha">
    <re-captcha
        #captchaRef="reCaptcha"
        [(ngModel)]="captcha"
        name="captcha"
        badge="inline"
        size="invisible"
        (resolved)="$event && handleResend()"
    ></re-captcha>
  </div>
</div>
