<latch-loading-bar
  [isActive]="isLoading"
  background="transparent"
  class="loading-bar"
></latch-loading-bar>

<img src="shared/assets/images/latch-logo.svg" alt="logo" class="logo" *ngIf="!isLoading" />
<ng-container *ngIf="isConfirmed">
  <h3>
    Success! Keycard (ending in {{lastFour}}) has been deactivated and now must be removed from your
    doors.
  </h3>
  <p>
    This Keycard may still open some doors until they are updated. We'll help you update them in the
    next couple steps.
  </p>
  <p>
    <button class="button button-upsell button-block" routerLink="/door-update-guide">
      Remove Keycard from Doors
    </button>
  </p>
</ng-container>
<ng-container *ngIf="isExpired">
  <h3>Keycard (ending in {{lastFour}}) is already deactivated!</h3>
  <p>
    Do you still need to remove Keycard from your doors? Some doors may still be opened with your
    Keycard until they are updated.
  </p>
  <p>
    To update your doors, <a routerLink="/door-update-guide">follow these steps</a>.
  </p>
</ng-container>
<ng-container *ngIf="isInvalid">
  <h3>This deactivation link is not valid.</h3>
  <p>
    Try opening the link in your deactivation email again, or
    <a routerLink="/deactivate">click here</a> to restart the deactivation process.
  </p>
</ng-container>
