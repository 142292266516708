import { Component, Input, forwardRef } from '@angular/core';
import { ValueAccessor } from 'shared/common/value-accessor';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'latch-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectComponent),
    multi: true
  }]
})
export class SelectComponent extends ValueAccessor {
  @Input() label = '';

  @Input() data: any[] = [];

  @Input() getDisplay = (item: any) => item;

  @Input() getValue = (item: any) => item;
}
