<latch-loading-bar
  [isActive]="isLoading"
  background="transparent"
  class="loading-bar"
></latch-loading-bar>

<img src="shared/assets/images/latch-logo.svg" alt="logo" class="logo" *ngIf="!isLoading" />
<ng-container *ngIf="isConfirmed">
  <div class="status-visual">
    <div class="icon-check-blue"></div>
  </div>
  <h3>
    Success! Keycard (ending in {{lastFour}}) has been activated and now must be added to your
    doors.
  </h3>
  <p>
    Your new Keycard can't open your doors until they are updated. We'll help you update them in the
    next couple steps.
  </p>
  <p>
    <button class="button button-upsell button-block" routerLink="/door-update-guide">
      Add Keycard to Doors
    </button>
  </p>
</ng-container>
<ng-container *ngIf="isExpired">
  <h3>Activation for Keycard (ending in {{lastFour}}) has expired.</h3>
  <p>
    Use the link in your email to activate the most recent Keycard or
    <a routerLink="/activate">click here</a> to restart the activation process.
  </p>
</ng-container>
<ng-container *ngIf="isAlreadyInUse">
  <h3>Keycard (ending in {{lastFour}}) has already been activated.</h3>
  <p>
    Do you still need to add Keycard to your doors? Some doors can't be opened with your Keycard
    until they are updated.
  </p>
  <p>
    To update your doors, <a routerLink="/door-update-guide">follow these steps</a>.
  </p>
</ng-container>
<ng-container *ngIf="isInvalid">
  <h3>This activation link is not valid.</h3>
  <p>
    Try opening the link in your activation email again, or
    <a routerLink="/activate">click here</a> to restart the activation process.
  </p>
</ng-container>
