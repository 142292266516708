import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Initiate activation
export const InvalidSerialNumberError = 'INVALID_SERIAL_NUMBER';
// When hitting the email availability endpoint, the backend will simply
// return a 400 if the serial number is invalid, the email is not a Latch
// email, the email is invalid, or the email already has an NFC credential
// associated. This is the error NFCService will throw in this case - note
// that the string is NOT what the backend returns; it is up to NFCService
// to translate the backend's 400 into this error type.
export const InvalidSerialNumberOrEmailError = 'INVALID_SERIAL_NUMBER_OR_EMAIL';

export const InvalidCaptchaError = 'INVALID_CAPTCHA';

// Confirm activation
export const InvalidTokenError = 'INVALID_TOKEN';
export const SerialNumberInUseError = 'SERIAL_NUMBER_IN_USE';

// Confirm deactivation
export const ExpiredTokenError = 'EXPIRED_TOKEN';
export const UserDoesNotHaveActiveNFCError = 'USER_DOES_NOT_HAVE_ACTIVE_NFC';
export const UserHasOtherNFCCredentialError = 'USER_HAS_OTHER_NFC_CREDENTIAL';

export const InvalidNFCSerialNumberError = 'INVALID_NFC_SERIAL_NUMBER';

export const UnknownServerError = 'UNKNOWN_SERVER_ERROR';

@Injectable()
export abstract class NFCService {

  abstract activate(email: string, serialNumber: string, captcha: string): Observable<any>;

  abstract confirmActivation(token: string): Observable<any>;

  abstract deactivate(email: string, captcha: string): Observable<any>;

  abstract confirmDeactivation(token: string): Observable<any>;

}
