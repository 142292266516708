import { Component, OnInit, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  NFCService,
  UnknownServerError,
  InvalidTokenError,
  ExpiredTokenError,
  UserDoesNotHaveActiveNFCError,
  UserHasOtherNFCCredentialError
} from '../../services/nfc.service';
import { FatalErrorService } from '../../services/fatal-error.service';
import { LatchAnalyticsService, LatchAnalyticsConstants } from '@latch/latch-web';

@Component({
  selector: 'latch-confirm-deactivation-page',
  templateUrl: './confirm-deactivation-page.component.html',
  styleUrls: ['./confirm-deactivation-page.component.scss']
})
export class ConfirmDeactivationPageComponent implements OnInit {

  @HostBinding('class.loading') isLoading = false;
  isConfirmed = false;
  isExpired = false;
  isInvalid = false;
  lastFour = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private nfcService: NFCService,
    private fatalErrorService: FatalErrorService,
    private analyticsService: LatchAnalyticsService
  ) { }

  ngOnInit() {
    const { queryParams } = this.activatedRoute.snapshot;

    const token = queryParams.token;
    if (!token) {
      this.isInvalid = true;
      return;
    }

    this.lastFour = queryParams.lastFour;
    if (!this.lastFour) {
      this.isInvalid = true;
      return;
    }

    this.isLoading = true;
    this.nfcService.confirmDeactivation(token).subscribe((response) => {
      this.isLoading = false;
      this.isConfirmed = true;
      this.trackSuccess();
    }, (error) => {
      this.isLoading = false;

      switch (error.message) {
        case ExpiredTokenError:
        case UserDoesNotHaveActiveNFCError:
          this.isExpired = true;
          this.trackError('Expired Deactivation Link');
          return;
        case InvalidTokenError:
        case UserHasOtherNFCCredentialError:
          this.isInvalid = true;
          this.trackError('Invalid Token');
          return;
        case UnknownServerError:
          this.fatalErrorService.isActive = true;
          this.trackError('Unknown');
          return;
        default:
          this.isInvalid = true;
          return;
      }
    });
  }

  trackSuccess() {
    this.analyticsService.track('Confirm Keycard Deactivation', {
      [LatchAnalyticsConstants.Success]: true,
      'Last Four': this.lastFour
    });
  }

  trackError(message: string) {
    this.analyticsService.track('Confirm Keycard Deactivation', {
      [LatchAnalyticsConstants.Success]: false,
      [LatchAnalyticsConstants.ErrorMessage]: message,
      'Last Four': this.lastFour
    });
  }

}
