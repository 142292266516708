import { Component, OnInit, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RecaptchaComponent } from 'ng-recaptcha';

import { ConstantsService } from '../../../shared/services/constants.service';
import {
  NFCService,
  UnknownServerError,
  InvalidCaptchaError, InvalidSerialNumberOrEmailError
} from '../../services/nfc.service';
import { FatalErrorService } from '../../services/fatal-error.service';
import { LatchAnalyticsService, LatchAnalyticsConstants } from '@latch/latch-web';

const stripSerialNumber = (serialNumber: string) => serialNumber.replace(/\D/g, '');

enum SerialNumberStatus {
  InProgress,
  Verifying,
  Invalid,
  Verified
}

enum RegistrationStatus {
  InProgress,
  Registering,
  Registered,
  Invalid
}

enum Step {
  EnterDetails,
  Confirmation
}

enum OverallError {
  InvalidEmail = 'INVALID_EMAIL',
  InvalidSerialNumberOrEmail = 'INVALID_SERIAL_NUMBER_OR_EMAIL'
}

@Component({
  selector: 'latch-activate-page',
  templateUrl: './activate-page.component.html',
  styleUrls: ['./activate-page.component.scss']
})
export class ActivatePageComponent implements OnInit {
  @ViewChild('form') ngForm!: NgForm;
  @ViewChild('captchaRef') reCaptcha!: RecaptchaComponent;

  // For focusing on inputs programmatically
  @ViewChild('serialNumberEl') serialNumberEl!: ElementRef;
  @ViewChild('emailEl') emailEl!: ElementRef;

  OverallError = OverallError;

  overallError: OverallError | undefined;

  serialNumberStatus: SerialNumberStatus = SerialNumberStatus.InProgress;
  registrationStatus: RegistrationStatus = RegistrationStatus.InProgress;

  step = Step;
  currentStep: Step = Step.EnterDetails;

  // Reference so we can use a setter
  _serialNumber = '';

  email = '';
  // Email string when submitted (for displaying in error messages)
  emailSubmitted = '';

  captcha = '';

  isResendingEmail = false;
  resendEmailSuccess = false;
  resendEmailError: string | undefined;

  public serialNumberMask = [
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/
  ];

  constructor(
    private constants: ConstantsService,
    private nfcService: NFCService,
    private fatalErrorService: FatalErrorService,
    private analyticsService: LatchAnalyticsService
  ) { }

  set serialNumber(value: string) {
    this._serialNumber = value;
    if (stripSerialNumber(value).length === this.constants.NFCSerialNumberLength) {
      this.handleVerify();
    } else {
      this.serialNumberStatus = SerialNumberStatus.InProgress;
      this.overallError = undefined;
    }
  }

  get serialNumber(): string {
    return this._serialNumber;
  }

  get rawSerialNumber() {
    return stripSerialNumber(this.serialNumber);
  }

  get isVerifying() {
    return this.serialNumberStatus === SerialNumberStatus.Verifying;
  }

  get isVerified() {
    return this.serialNumberStatus === SerialNumberStatus.Verified;
  }

  @HostBinding('class.loading')
  get isRegistering() {
    return this.registrationStatus === RegistrationStatus.Registering;
  }

  get isRegistered() {
    return this.registrationStatus === RegistrationStatus.Registered;
  }

  get isSubmitting() {
    return this.isVerifying || this.isRegistering;
  }

  get serialNumberError() {
    return this.serialNumberStatus === SerialNumberStatus.Invalid ? 'Enter a valid serial number' : null;
  }

  get emailIsValid() {
    const emailControl = this.ngForm.form.get('email');
    return emailControl && emailControl.valid;
  }

  get canSubmit() {
    return this.isVerified && this.ngForm.valid;
  }

  ngOnInit() {
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
      [LatchAnalyticsConstants.PageName]: 'Activate Keycard'
    });
  }

  handleVerify() {
    this.serialNumberStatus = SerialNumberStatus.Verified;
  }

  handleSubmitPreCaptcha(evt: Event): boolean {
    evt.preventDefault();
    this.overallError = undefined;
    this.emailSubmitted = this.email;
    if (!this.emailIsValid) {
      this.overallError = OverallError.InvalidEmail;
      return false;
    }
    return true;
  }

  handleSubmitPostCaptcha() {
    if (!this.canSubmit) {
      this.reCaptcha.reset();
      return;
    }

    // Ensure that user sees loading bar on mobile
    window.scrollTo(0, 0);

    this.registrationStatus = RegistrationStatus.Registering;

    this.nfcService.activate(this.email, this.rawSerialNumber, this.captcha).subscribe((response) => {
      this.registrationStatus = RegistrationStatus.Registered;
      this.currentStep = Step.Confirmation;
      this.trackSuccess();
    }, (error) => {
      this.registrationStatus = RegistrationStatus.Invalid;
      this.reCaptcha.reset();
      switch (error.message) {
        case InvalidSerialNumberOrEmailError:
          this.overallError = OverallError.InvalidSerialNumberOrEmail;
          this.trackError(this.overallError);
          break;
        case InvalidCaptchaError:
        case UnknownServerError:
        default:
          this.fatalErrorService.isActive = true;
          this.trackError('Unknown');
          break;
      }
    });
  }

  trackSuccess() {
    this.analyticsService.track('Activate Keycard', {
      [LatchAnalyticsConstants.Success]: true,
      'Serial Number': this.rawSerialNumber
    });
  }

  trackError(message: string) {
    this.analyticsService.track('Activate Keycard', {
      [LatchAnalyticsConstants.Success]: false,
      [LatchAnalyticsConstants.ErrorMessage]: message,
      'Serial Number': this.rawSerialNumber
    });
  }

  handleResend() {
    this.resendEmailSuccess = false;
    this.resendEmailError = undefined;
    this.isResendingEmail = true;
    this.nfcService.activate(this.email, this.rawSerialNumber, this.captcha).subscribe((response) => {
      this.reCaptcha.reset();
      this.isResendingEmail = false;
      this.resendEmailSuccess = true;
    }, (error) => {
      this.reCaptcha.reset();
      this.isResendingEmail = false;
      this.resendEmailError = 'There was a problem trying to resend your email, please try ' +
        'again in a few minutes, or contact support@latch.com.';
    });
  }

}
