import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TextMaskModule } from 'angular2-text-mask';

import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { SpacerComponent } from './components/spacer/spacer.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { TrackDirective } from './directives/track.directive';

import { SelectModule } from './components/select/select.module';
import { MenuDropdownModule } from './components/menu-dropdown/menu-dropdown.module';
import { ScrolledToEndDirective } from './directives/scrolled-to-end.directive';
import { LatchWebModule } from './latch-web.module';

@NgModule({
  imports: [
    LatchWebModule,
    TextMaskModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  declarations: [
    LoadingBarComponent,
    AutofocusDirective,
    TrackDirective,
    SpacerComponent,
    ScrolledToEndDirective,
  ],
  exports: [
    LatchWebModule,
    TextMaskModule,
    LoadingBarComponent,
    AutofocusDirective,
    TrackDirective,
    SpacerComponent,
    SelectModule,
    MenuDropdownModule,
    ScrolledToEndDirective,
  ],
  providers: []
})
export class SharedModule { }
