import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PlatformService } from '../../services/platform.service';
import { LatchAnalyticsService, LatchAnalyticsConstants } from '@latch/latch-web';
import { map, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'latch-door-update-guide-page',
  templateUrl: './door-update-guide-page.component.html',
  styleUrls: ['./door-update-guide-page.component.scss']
})
export class DoorUpdateGuidePageComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private platformService: PlatformService,
    private analyticsService: LatchAnalyticsService
  ) { }

  get stepPath(): string {
    return this.activatedRoute.snapshot.url[1].path;
  }

  get checkUseApp(): boolean {
    return this.stepPath === 'check-use-app';
  }

  get updateWithApp(): boolean {
    return this.stepPath === 'update-with-latch-app';
  }

  get unlockWithApp(): boolean {
    return this.stepPath === 'unlock-with-latch-app';
  }

  get cantUseApp(): boolean {
    return this.stepPath === 'cant-use-app';
  }

  ngOnInit() {
    this.activatedRoute.url.pipe(
      map((url) => url[1].path),
      distinctUntilChanged()
    ).subscribe((path) => this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
      [LatchAnalyticsConstants.PageName]: _.startCase(path)
    }));
  }

  handleOpenLatchApp() {
    window.open(this.platformService.linkToLatchApp, '_blank');
  }

}
