import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'latch-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss']
})
export class MenuDropdownComponent {
  @HostBinding('class.dropdown') dropdownClass = true;
}
