import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { FatalErrorService } from '../services/fatal-error.service';

/**
 * Shell component which contains the entire Keycard app.
 */
@Component({
  selector: 'latch-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private titleService: Title,
    private fatalErrorService: FatalErrorService
  ) { }

  get hasFatalError() {
    return this.fatalErrorService.isActive;
  }

  ngOnInit() {
    this.titleService.setTitle('Latch | Keycard');
  }

}
