import { ControlValueAccessor } from '@angular/forms';
import { Input, Directive } from '@angular/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ValueAccessor implements ControlValueAccessor {
  protected _disabled = false;
  @Input()
  public set disabled(value: boolean) {
    this.setDisabledState(value);
  }
  public get disabled(): boolean {
    return this._disabled;
  }

  private _value: any;
  public get value(): any {
    return this._value;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange = (value: any): void => { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = (): void => { };

  private notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this._value);
    }
  }

  public updateAndNotify(value: any): void {
    this._value = value;
    this.notifyValueChange();
  }

  public blur(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  // #region ControlValueAccessor

  public writeValue(value: any): void {
    this._value = value;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  // #endregion
}
