<latch-loading-bar
  [isActive]="isRegistering"
  background="transparent"
  class="loading-bar"
></latch-loading-bar>

<img src="shared/assets/images/latch-logo.svg" alt="logo" class="logo" />
<h2>Activate Keycard</h2>

<!-- `action` attribute on the form makes the return key say "Go" on iOS Safari -->
<form
  #form="ngForm"
  (ngSubmit)="handleSubmitPreCaptcha($event) && captchaRef.execute()"
  action
  *ngIf="currentStep === step.EnterDetails"
>
  <div class="form-group" [class.has-error]="serialNumberError">
    <label class="form-control-label">Enter serial number on card</label>
    <div class="input-group">
      <!-- type="tel" gives us numeric keyboard on iOS Safari without preventing our dashes -->
      <input
        type="tel"
        class="form-control serial-number-control"
        name="serialNumber"
        autocomplete="off"
        [(ngModel)]="serialNumber"
        [textMask]="{mask: serialNumberMask, guide: false}"
        [disabled]="isSubmitting"
        latchAutofocus
        #serialNumberEl
      />
    </div>
    <div class="form-control-feedback" [class.invisible]="!serialNumberError">
      {{serialNumberError}}
    </div>
  </div>
  <div class="form-group" [class.has-error]="overallError === OverallError.InvalidEmail" *ngIf="isVerified">
    <label class="form-control-label">Enter your Latch account email</label>
    <input
      type="email"
      class="form-control"
      name="email"
      autocomplete="off"
      [(ngModel)]="email"
      [disabled]="isSubmitting"
      latchAutofocus
      email
      required
      #emailEl
    />
    <div class="form-control-feedback" [class.invisible]="overallError !== OverallError.InvalidEmail">
      <ng-container *ngIf="overallError === OverallError.InvalidEmail">
        {{emailSubmitted}} is not a valid email
      </ng-container>
    </div>
  </div>
  <div class="text-danger overall" *ngIf="overallError === OverallError.InvalidSerialNumberOrEmail">
    Keycard activation failed for one of the following reasons:
    <ul>
      <li>Incorrect serial number</li>
      <li>Incorrect email</li>
      <li>{{emailSubmitted}} already has a Keycard</li>
    </ul>
  </div>
  <div class="actions-container" [class.invisible]="!isVerified">
    <div class="actions">
      <button type="submit" class="button button-upsell">Next</button>
    </div>
  </div>
  <div class="captcha">
    <re-captcha
        #captchaRef="reCaptcha"
        [(ngModel)]="captcha"
        name="captcha"
        badge="inline"
        size="invisible"
        (resolved)="$event && handleSubmitPostCaptcha()"
    ></re-captcha>
  </div>
</form>

<div *ngIf="currentStep === step.Confirmation">
  <h3>We've sent an activation email</h3>
  <p>Open the link in the email to activate your Latch Keycard.</p>
  <div class="resend-email">
    <button
      class="button button-outline-secondary"
      (click)="captchaRef.execute()"
      [disabled]="isResendingEmail"
    >
      Resend email
    </button>
    <div class="status" *ngIf="isResendingEmail">
      <div class="indicator spinner-ring"></div> Resending...
    </div>
    <div class="status" *ngIf="resendEmailSuccess">
      <i class="indicator icon-check"></i> Re-sent
    </div>
    <div class="status text-danger" *ngIf="resendEmailError">
      {{resendEmailError}}
    </div>
  </div>
  <div class="captcha">
    <re-captcha
        #captchaRef="reCaptcha"
        [(ngModel)]="captcha"
        name="captcha"
        badge="inline"
        size="invisible"
        (resolved)="$event && handleResend()"
    ></re-captcha>
  </div>
</div>
