import { Environment } from './interface';

export const environment: Environment = {
  production: false,
  apiBaseUrl: 'https://api-staging.latch.com',
  selectAccount: '9ee42122',
  useMocks: false,
  name: 'staging',
  enableLogging: true,
  datadogToken: 'pub14a05d477f7a66126a4bf7ce1d2c4169',
  enableAnalytics: true,
  heapApplicationId: '999849600',
  reCaptchaToken: '6Lfhh1oUAAAAAC6DFPsnJMPgfBdxjOAft8x8NJPa',
  enableSmoochChat: false,
  smoochIntegrationId: '',
  auth0Config: {
    domain: 'auth.staging.latch.com',
    clientId: 'vHrYsBMS3bRu6GcG2Nbt9nRkJ5ytXOys',
    redirectUri: window.location.origin,
    audience: 'https://api-staging.latch.com',
    serverUrl: 'https://api-staging.latch.com',
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://api-staging.latch.com/web/v2/user-accounts/password/reset',
          allowAnonymous: true
        } as any as string,
        // CWL-126 - once we update the types for allowedList we can remove this cast
        'https://api-staging.latch.com/web/*'
        // Ordering seems to matter here, though I can't find documentation of this fact.
        // We want to require tokens for /web/* paths, EXCEPT for /web/v2/user-accounts/password/reset
      ]
    }
  },
  enableFeaturedEntries: false
};
