import { Component, OnInit, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NFCService, UnknownServerError, InvalidCaptchaError, InvalidSerialNumberOrEmailError } from '../../services/nfc.service';
import { FatalErrorService } from '../../services/fatal-error.service';
import { LatchAnalyticsService, LatchAnalyticsConstants } from '@latch/latch-web';
import { RecaptchaComponent } from 'ng-recaptcha';

enum SubmissionStatus {
  InProgress,
  Submitting,
  Submitted,
  Invalid
}

enum Step {
  EnterDetails,
  Confirmation
}

enum SendEmailError {
  InvalidEmailFormat = 'INVALID_EMAIL_FORMAT',
  InvalidSerialNumberOrEmail = 'INVALID_SERIAL_NUMBER_OR_EMAIL'
}

@Component({
  selector: 'latch-deactivate-page',
  templateUrl: './deactivate-page.component.html',
  styleUrls: ['./deactivate-page.component.scss']
})
export class DeactivatePageComponent implements OnInit {

  @ViewChild('form') ngForm!: NgForm;
  @ViewChild('emailEl') emailEl!: ElementRef;
  @ViewChild('captchaRef') reCaptcha!: RecaptchaComponent;

  submissionStatus: SubmissionStatus = SubmissionStatus.InProgress;

  step = Step;
  currentStep: Step = Step.EnterDetails;

  // Reference so we can use a setter
  _email = '';

  captcha = '';

  SendEmailError = SendEmailError;
  sendEmailError: SendEmailError | undefined;

  isResendingEmail = false;
  resendEmailSuccess = false;
  resendEmailError: string | undefined;

  constructor(
    private nfcService: NFCService,
    private fatalErrorService: FatalErrorService,
    private analyticsService: LatchAnalyticsService
  ) { }

  set email(value: string) {
    this.submissionStatus = SubmissionStatus.InProgress;
    this._email = value;
  }

  get email(): string {
    return this._email;
  }

  @HostBinding('class.loading')
  get isSubmitting() {
    return this.submissionStatus === SubmissionStatus.Submitting;
  }

  get isSubmitted() {
    return this.submissionStatus === SubmissionStatus.Submitted;
  }

  get emailIsValid(): boolean {
    const emailControl = this.ngForm && this.ngForm.form.get('email');
    return (emailControl !== undefined && emailControl !== null) && emailControl.valid;
  }

  get canSubmit(): boolean {
    return !!this.ngForm.valid;
  }

  ngOnInit() {
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
      [LatchAnalyticsConstants.PageName]: 'Deactivate Keycard'
    });
  }

  handleSubmitPreCaptcha(evt: Event): boolean {
    evt.preventDefault();
    this.sendEmailError = undefined;
    if (!this.emailIsValid) {
      this.sendEmailError = SendEmailError.InvalidEmailFormat;
      return false;
    }
    return true;
  }

  handleSubmitPostCaptcha() {
    if (!this.canSubmit) {
      this.reCaptcha.reset();
      return;
    }

    // Ensure that user sees loading bar on mobile
    window.scrollTo(0, 0);

    this.submissionStatus = SubmissionStatus.Submitting;
    this.nfcService.deactivate(this.email, this.captcha).subscribe((response) => {
      this.submissionStatus = SubmissionStatus.Submitted;
      this.currentStep = Step.Confirmation;
      this.trackSuccess();
    }, (error) => {
      this.submissionStatus = SubmissionStatus.Invalid;
      this.reCaptcha.reset();
      switch (error.message) {
        case InvalidSerialNumberOrEmailError:
          this.sendEmailError = SendEmailError.InvalidSerialNumberOrEmail;
          this.focusEmail();
          this.trackError('Invalid Email');
          break;
        case InvalidCaptchaError:
        case UnknownServerError:
        default:
          this.fatalErrorService.isActive = true;
          this.trackError('Unknown');
          break;
      }
    });
  }

  focusEmail() {
    // Can't create focus while input is disabled
    setTimeout(() => {
      this.emailEl.nativeElement.focus();
    });
  }

  trackSuccess() {
    this.analyticsService.track('Deactivate Keycard', {
      [LatchAnalyticsConstants.Success]: true,
    });
  }

  trackError(message: string) {
    this.analyticsService.track('Deactivate Keycard', {
      [LatchAnalyticsConstants.Success]: false,
      [LatchAnalyticsConstants.ErrorMessage]: message,
    });
  }

  handleResend() {
    // Ensure that user sees loading bar on mobile
    window.scrollTo(0, 0);

    this.resendEmailSuccess = false;
    this.resendEmailError = undefined;
    this.isResendingEmail = true;
    this.nfcService.deactivate(this.email, this.captcha).subscribe(() => {
      this.reCaptcha.reset();
      this.isResendingEmail = false;
      this.resendEmailSuccess = true;
    }, () => {
      this.reCaptcha.reset();
      this.isResendingEmail = false;
      this.resendEmailError = 'There was a problem trying to resend your email, please try ' +
        'again in a few minutes, or contact support@latch.com.';
    });
  }

}
