import { Directive, OnInit, ElementRef } from '@angular/core';

// Directive to make [autofocus] work anytime the element gets added to the DOM,
// vs browser default of just the first time / on page load.
@Directive({
  selector: '[latchAutofocus]'
})
export class AutofocusDirective implements OnInit {
  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.el.nativeElement.focus();
  }
}
