import { Component, OnChanges, HostBinding, SimpleChanges, Input } from '@angular/core';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

const MAX_PROGRESS_DURATION = 10000;
const COMPLETION_DURATION = 300;

export const ProgressStatus = {
  Initialize: 0,
  Progress: 0.2,
  Complete: 1
};

/**
 * This loading bar is designed to mimic the gradual-then-quick loading effect popular in many
 * applications of late, such as Safari, Medium, and Github.
 *
 * In this first iteration, it doesn't actually capture any incremental progress, it just moves
 * through a standard animation based on "start" => "progress" => "complete" states.
 *
 * The animation can be managed by specifying a granular `percentage` value (0-1), or simply via an
 * `isActive` flag. With `isActive`, changing from `false` to `true` will automatically start the
 * animation into its progress phase, whereas specifying `percentage` requires at least 2
 * asynchronous updates before any animation will begin (setting to 0 and then to >0).
 */
@Component({
  selector: 'latch-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  animations: [
    trigger('phase', [
      state('start', style({ width: 0 })),
      state('progress', style({ width: '40%' })),
      state('complete', style({ width: '100%' })),
      transition('start => progress', [
        // Spend MAX_PROGRESS_DURATION (not interpolated cuz that breaks AOT build) filling out 40%
        // of the bar, starting quickly and then proceeding very slowly as "progress" occurs. This
        // gives the operation a while for it to complete before the animation completely stalls at
        // 40%. If it finishes faster than that, we'll zip right to the end.
        animate('10s ease-out', keyframes([
          style({ width: 0, offset: 0 }),
          style({ width: '20%', offset: 0.1 }),
          style({ width: '40%', offset: 1 })
        ]))
      ]),
      transition('progress => complete', [
        // All done, use COMPLETION_DURATION (not interpolated for AOT build) to zip to the finish!
        animate('300ms ease-out', style({ width: '100%' }))
      ]),
      transition('start => complete', [
        animate('300ms ease-out', keyframes([
          style({ width: 0, offset: 0 }),
          style({ width: '20%', offset: 0.1 }),
          style({ width: '100%', offset: 1 })
        ]))
      ])
    ])
  ]
})
export class LoadingBarComponent implements OnChanges {

  @HostBinding('class.invisible') isHidden = false;

  @HostBinding('style.background-color')
  @Input() background: string | undefined;

  @Input() percentage = 0;
  @Input() isActive = false;

  phase = 'start';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.percentage && changes.isActive) {
      throw new Error('May only use one of percentage and isActive');
    }
    if (changes.percentage) {
      this.handlePercentage(changes.percentage);
    }
    if (changes.isActive) {
      this.handleActive(changes.isActive);
    }
  }

  handlePercentage({ currentValue, previousValue }:
    { currentValue: number, previousValue: number }) {
    if (
      // 0% means start at the beginning
      currentValue === 0 ||
      // Clients may not have a chance to set to 0 before progress occurs (e.g. if request begins
      // during the same call stack as this component is mounted), so treat going from undefined to
      // anything as a trigger to start at the beginning
      previousValue === undefined ||
      // This should not happen, but in the event a client decrements progress, assume it's an
      // attempt to start over (e.g. if a client retries a request and isn't able to set to 0)
      currentValue < previousValue
    ) {
      this.handleStart();
    } else if (currentValue < 1) {
      this.handleProgress();
    } else {
      this.handleComplete();
    }
  }

  handleActive({ currentValue, previousValue }:
    { currentValue: boolean, previousValue: boolean }) {
    // false -> true
    if (!previousValue && currentValue) {
      this.handleStart();
      setTimeout(() => this.handleProgress());
      // true -> false
    } else if (previousValue && !currentValue) {
      this.handleComplete();
    }
  }

  handleStart() {
    this.phase = 'start';
    this.isHidden = false;
  }

  handleProgress() {
    this.phase = 'progress';
    this.isHidden = false;
  }

  handleComplete() {
    this.phase = 'complete';
    // Hide the bar after the completion animation completes
    setTimeout(() => {
      this.isHidden = true;
      // Restore to starting state after fade out animation completes
      setTimeout(() => this.phase = 'start', COMPLETION_DURATION);
    }, COMPLETION_DURATION);
  }

}
