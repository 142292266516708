import { Component, OnInit, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  NFCService,
  InvalidTokenError,
  SerialNumberInUseError,
  InvalidSerialNumberError,
  UnknownServerError
} from '../../services/nfc.service';
import { FatalErrorService } from '../../services/fatal-error.service';
import { LatchAnalyticsService, LatchAnalyticsConstants } from '@latch/latch-web';

@Component({
  selector: 'latch-confirm-activation-page',
  templateUrl: './confirm-activation-page.component.html',
  styleUrls: ['./confirm-activation-page.component.scss']
})
export class ConfirmActivationPageComponent implements OnInit {

  @HostBinding('class.loading') isLoading = false;
  isConfirmed = false;
  isExpired = false;
  isAlreadyInUse = false;
  isInvalid = false;
  lastFour = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private nfcService: NFCService,
    private fatalErrorService: FatalErrorService,
    private analyticsService: LatchAnalyticsService
  ) { }

  ngOnInit() {
    const { queryParams } = this.activatedRoute.snapshot;

    const token = queryParams.token;
    if (!token) {
      this.isInvalid = true;
      return;
    }

    this.lastFour = queryParams.lastFour;
    if (!this.lastFour) {
      this.isInvalid = true;
      return;
    }

    this.isLoading = true;
    this.nfcService.confirmActivation(token).subscribe((response) => {
      this.isLoading = false;
      this.isConfirmed = true;
      this.trackSuccess();
    }, (error) => {
      this.isLoading = false;

      switch (error.message) {
        case InvalidTokenError:
          this.isInvalid = true;
          this.trackError('Invalid Token');
          return;
        case SerialNumberInUseError:
          this.isAlreadyInUse = true;
          this.trackError('Already In Use');
          return;
        case InvalidSerialNumberError:
          this.isExpired = true;
          this.trackError('Invalid Serial Number');
          return;
        case UnknownServerError:
          this.fatalErrorService.isActive = true;
          this.trackError('Unknown');
          return;
        default:
          this.isInvalid = true;
          return;
      }
    });
  }

  trackSuccess() {
    this.analyticsService.track('Confirm Keycard Activation', {
      [LatchAnalyticsConstants.Success]: true,
      'Last Four': this.lastFour
    });
  }

  trackError(message: string) {
    this.analyticsService.track('Confirm Keycard Activation', {
      [LatchAnalyticsConstants.Success]: false,
      [LatchAnalyticsConstants.ErrorMessage]: message,
      'Last Four': this.lastFour
    });
  }

}
