<img src="shared/assets/images/latch-logo.svg" alt="logo" class="logo" />

<ng-container *ngIf="checkUseApp">
  <h3>Do you already use the Latch app?</h3>
  <a routerLink="../update-with-latch-app" class="link-row">
    Yes <i class="icon-chevron-right"></i>
  </a>
  <a routerLink="../unlock-with-latch-app" class="link-row">
    No <i class="icon-chevron-right"></i>
  </a>
</ng-container>

<ng-container *ngIf="updateWithApp">
  <a routerLink="../check-use-app" class="back">
    <i class="icon-chevron-left"></i> Back
  </a>
  <h3>How to update with the Latch app</h3>
  <p>If you've already opened your doors with the Latch app, follow the steps linked here:</p>
  <a
    href="https://support.latch.com/hc/en-us/articles/115013163648"
    target="_blank"
    class="link-row"
    latchTrack="Click Zendesk Update Doors"
  >
    How to update your doors
  </a>
</ng-container>

<ng-container *ngIf="unlockWithApp">
  <a routerLink="../check-use-app" class="back">
    <i class="icon-chevron-left"></i> Back
  </a>
  <h3>Open your doors with the Latch app</h3>
  <p>
    Keycard will automatically be added to (or removed from) your doors when you open them for the
    first time with the Latch app.
  </p>
  <a
    href="javascript:void(0)"
    class="link-row"
    latchTrack="Click Open Latch App"
    (click)="handleOpenLatchApp()"
  >
    Use the Latch app to open your doors
  </a>
  <a routerLink="../cant-use-app" class="link-row">
    I can't use the Latch app <i class="icon-chevron-right"></i>
  </a>
</ng-container>

<ng-container *ngIf="cantUseApp">
  <a routerLink="../unlock-with-latch-app" class="back">
    <i class="icon-chevron-left"></i> Back
  </a>
  <h3>I can't use the Latch app</h3>
  <p>Contact your building's management so that they can update your doors.</p>
</ng-container>
