import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import {
  NFCService,
  InvalidSerialNumberOrEmailError,
  InvalidCaptchaError,
  UnknownServerError
} from './nfc.service';
import { APIService } from '../../shared/services/api.service';
import { catchError } from 'rxjs/operators';
import { getPayload } from '@latch/latch-web';

@Injectable()
export class HTTPNFCService extends NFCService {

  constructor(
    private apiService: APIService
  ) {
    super();
  }

  // Any time we change any of these endpoints, we also need to update shared/api-service to correctly generalize
  // these endpoints (strip NFC number and email out of endpoint so they are correctly grouped together and not
  // treated as a different type of error for each number and email). Ugly that we do that so deep down in the
  // APIService layer but it's what we have right now.

  activate(email: string, serialNumber: string, captcha: string): Observable<any> {
    email = email && email.toLowerCase(); // TODO: Email is canse-sensitive in api. Remove after LMC-1353
    return this.apiService
      .request('post', '/web/v2/users/nfc', {
        email,
        nfcSerialNumber: serialNumber,
        captcha
      })
      .pipe(catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 400) {
          const message = getPayload(error);
          if (message === 'CAPTCHA_ERROR') {
            return throwError(new Error(InvalidCaptchaError));
          } else {
            return throwError(new Error(InvalidSerialNumberOrEmailError));
          }
        } else {
          return throwError(new Error(UnknownServerError));
        }
      }));
  }

  confirmActivation(token: string): Observable<any> {
    return this.apiService
      .request('post', `/web/v1/users/nfc/${token}`, {})
      .pipe(catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status < 500) {
          const message = getPayload(error) as string;
          return throwError(new Error(message));
        } else {
          return throwError(new Error(UnknownServerError));
        }
      }));
  }

  deactivate(email: string, captcha: string): Observable<any> {
    email = email && email.toLowerCase(); // TODO: Email is canse-sensitive in api. Remove after LMC-1353
    return this.apiService
      .request('post', '/web/v2/users/deactivate/nfc', {
        email,
        captcha
      })
      .pipe(catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 400) {
          const message = getPayload(error);
          if (message === 'CAPTCHA_ERROR') {
            return throwError(new Error(InvalidCaptchaError));
          } else {
            return throwError(new Error(InvalidSerialNumberOrEmailError));
          }
        } else {
          return throwError(new Error(UnknownServerError));
        }
      }));
  }

  confirmDeactivation(token: string): Observable<any> {
    return this.apiService
      .request('post', `/web/v1/users/deactivate/nfc/${token}`, {})
      .pipe(catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status < 500) {
          const message = getPayload(error) as string;
          return throwError(new Error(message));
        } else {
          return throwError(new Error(UnknownServerError));
        }
      }));
  }

}
