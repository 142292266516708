import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class APIService {
  constructor(
    private http: HttpClient
  ) { }

  request(method: string,
    endpoint: string,
    data?: Record<string, any>,
    headers?: HttpHeaders | { [name: string]: string | string[] },
    search?: HttpParams | { [param: string]: string | string[] },
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text'): Observable<HttpResponse<any>> {

    const url = environment.apiBaseUrl + endpoint;
    const httpHeaders = headers instanceof HttpHeaders ?
      headers.set('Content-Type', 'application/json') :
      new HttpHeaders({ ...headers, 'Content-Type': 'application/json' });
    const httpParams = search instanceof HttpParams ?
      search : new HttpParams({ fromObject: search });

    return this.http.request(method, url, {
      headers: httpHeaders,
      params: httpParams,
      body: data,
      observe: 'response', // get the full response
      responseType,
      withCredentials: true
    });
  }
}
