import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LatchAnalyticsModule, LatchLogModule, LATCH_APPLICATION_NAME } from '@latch/latch-web';
import { environment } from 'environments/environment';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { SharedModule } from '../shared/shared.module';
import { ActivatePageComponent } from './components/activate/activate-page.component';
import { ConfirmActivationPageComponent } from './components/activate/confirm-activation-page.component';
import { AppComponent } from './components/app.component';
import { ConfirmDeactivationPageComponent } from './components/deactivate/confirm-deactivation-page.component';
import { DeactivatePageComponent } from './components/deactivate/deactivate-page.component';
import { DoorUpdateGuidePageComponent } from './components/door-update-guide/door-update-guide-page.component';
import { IndexPageComponent } from './components/index/index-page.component';
import { NotFoundComponent } from './components/not-found.component';
import { HTTPNFCService } from './services/http-nfc.service';
import { MockNFCService } from './services/mock-nfc.service';
import { NFCService } from './services/nfc.service';

const APPLICATION_NAME = 'Latch-KeycardWeb';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    IndexPageComponent,
    ActivatePageComponent,
    ConfirmActivationPageComponent,
    DoorUpdateGuidePageComponent,
    DeactivatePageComponent,
    ConfirmDeactivationPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forRoot([
      { path: '', component: IndexPageComponent },
      { path: 'activate', component: ActivatePageComponent },
      { path: 'confirm-activation', component: ConfirmActivationPageComponent },
      { path: 'deactivate', component: DeactivatePageComponent },
      { path: 'confirm-deactivation', component: ConfirmDeactivationPageComponent },
      { path: 'door-update-guide/check-use-app', component: DoorUpdateGuidePageComponent },
      { path: 'door-update-guide/update-with-latch-app', component: DoorUpdateGuidePageComponent },
      { path: 'door-update-guide/unlock-with-latch-app', component: DoorUpdateGuidePageComponent },
      { path: 'door-update-guide/cant-use-app', component: DoorUpdateGuidePageComponent },
      { path: 'door-update-guide', redirectTo: '/door-update-guide/check-use-app' },
      { path: '404', component: NotFoundComponent },
      { path: '**', redirectTo: '/404' }
    ], {}),
    RecaptchaModule,
    RecaptchaFormsModule,
    LatchLogModule.forRoot({
      enableLogging: environment.enableLogging,
      datadog: environment.datadogToken ? {
        datadogToken: environment.datadogToken,
        environmentName: environment.name,
        service: 'keycard-frontend'
      } : undefined,
    }),
    LatchAnalyticsModule.forRoot({
      enableAnalytics: environment.enableAnalytics ?? false,
      environmentName: environment.name,
      heap: environment.heapApplicationId ? {
        applicationId: environment.heapApplicationId,
        client: APPLICATION_NAME,
      } : undefined,
    }),
  ],
  providers: [
    { provide: LATCH_APPLICATION_NAME, useValue: APPLICATION_NAME },
    { provide: NFCService, useClass: environment.useMocks ? MockNFCService : HTTPNFCService },
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.reCaptchaToken } as RecaptchaSettings },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
