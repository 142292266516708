import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { LatchIconModule, LatchIconService } from '@latch/latch-web';
import { MenuDropdownComponent } from './menu-dropdown.component';

@NgModule({
  imports: [CommonModule, LatchIconModule],
  exports: [MenuDropdownComponent],
  declarations: [MenuDropdownComponent],
  providers: [],
})
export class MenuDropdownModule {
  constructor(icon: LatchIconService) {
    icon.library.addIcons(faCaretDown);
  }
}
