import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  NFCService,
  InvalidSerialNumberError,
  InvalidSerialNumberOrEmailError,
  InvalidCaptchaError,
  UnknownServerError,
  InvalidTokenError,
  SerialNumberInUseError,
  ExpiredTokenError,
  UserDoesNotHaveActiveNFCError,
  UserHasOtherNFCCredentialError
} from './nfc.service';
import { mockResponse } from '../../shared/utility/mock-response';

@Injectable()
export class MockNFCService extends NFCService {

  nfcCards = [
    '123412341234',
    '234523452345',
    '345634563456',
    '456745674567',
    '567856785678',
    '678967896789'
  ];

  cardsInUse = [this.nfcCards[0]];

  activate(email: string, serialNumber: string, captcha: string): Observable<any> {
    return mockResponse(() => {
      if (email === 'doesNotExist@email.com') {
        throw new Error(InvalidSerialNumberOrEmailError);
      } else if (email === 'userAlreadyHasNFC@email.com') {
        throw new Error(InvalidSerialNumberOrEmailError);
      } else if (email === 'unexpectedServerError@email.com') {
        throw new Error(UnknownServerError);
      } else if (email === 'invalidCaptcha@email.com') {
        throw new Error(InvalidCaptchaError);
      } else {
        return 'registered';
      }
    });
  }

  confirmActivation(token: string): Observable<any> {
    return mockResponse(() => {
      if (token === 'validToken') {
        return 'confirmed';
      } else if (token === 'serialNumberInUseToken') {
        throw new Error(SerialNumberInUseError);
      } else if (token === 'unknownServerErrorToken') {
        throw new Error(UnknownServerError);
      } else if (token === 'invalidSerialNumberToken') {
        throw new Error(InvalidSerialNumberError);
      } else {
        throw new Error(InvalidTokenError);
      }
    });
  }

  deactivate(email: string, captcha: string): Observable<any> {
    return mockResponse(() => {
      if (email === 'unknownServerError@email.com') {
        throw new Error(UnknownServerError);
      } else if (email === 'invalidCaptcha@email.com') {
        throw new Error(InvalidCaptchaError);
      } else if (email === 'invalidEmail@email.com') {
        throw new Error(InvalidSerialNumberOrEmailError);
      } else if (email === 'invalidSerial@email.com') {
        throw new Error(InvalidSerialNumberOrEmailError);
      } else {
        return 'deactivation request submitted';
      }
    });
  }

  confirmDeactivation(token: string): Observable<any> {
    return mockResponse(() => {
      if (token === 'validToken') {
        return 'confirmed';
      } else if (token === 'expiredToken') {
        throw new Error(ExpiredTokenError);
      } else if (token === 'noActiveKeycardToken') {
        throw new Error(UserDoesNotHaveActiveNFCError);
      } else if (token === 'hasOtherKeycardToken') {
        throw new Error(UserHasOtherNFCCredentialError);
      } else if (token === 'unknownServerErrorToken') {
        throw new Error(UnknownServerError);
      } else {
        throw new Error(InvalidTokenError);
      }
    });
  }

}
