import { Injectable } from '@angular/core';

const ANDROID_RE = /android/i;
const IOS_RE = /iPad|iPhone|iPod/i;

@Injectable({ providedIn: 'root' })
export class PlatformService {

  get isAndroid() {
    return ANDROID_RE.test(navigator.userAgent);
  }

  get isIOS() {
    return IOS_RE.test(navigator.userAgent);
  }

  get linkToLatchApp() {
    if (this.isAndroid) {
      return 'https://play.google.com/store/apps/details?id=com.latch.android.latchapp&hl=en';
    } else if (this.isIOS) {
      return 'https://itunes.apple.com/us/app/latch-app/id1062355375';
    } else {
      return 'https://support.latch.com/hc/en-us/articles/115011155947-Supported-devices';
    }
  }

}
