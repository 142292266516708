import { Directive, Input, HostListener } from '@angular/core';
import { LatchAnalyticsService } from '@latch/latch-web';

@Directive({
  selector: '[latchTrack]'
})
export class TrackDirective {
  @Input() latchTrack = '';
  @Input() latchTrackProperties: Record<string, any> = {};

  constructor(private analyticsService: LatchAnalyticsService) { }

  @HostListener('click') handleClick() {
    this.analyticsService.track(this.latchTrack, this.latchTrackProperties);
  }
}
