<div class="hero">
  <img src="keycard/assets/images/keycard-demo.gif" class="keycard-photo" />
</div>
<h2>Latch Keycard</h2>
<p>
  Latch Keycard is a contactless way to open all your doors. Just tap the Latch circle and hold your
  Keycard near to open.
</p>
<button class="button button-upsell button-block" routerLink="/activate">
  Activate Keycard
</button>
<a class="alternative" routerLink="/deactivate">
  Deactivate Keycard
</a>
