<label class="label">{{ label }}</label>
<select
  class="select"
  [ngModel]="value"
  (ngModelChange)="updateAndNotify($event)"
  [disabled]="disabled">
  <option
    *ngFor="let item of data"
    [value]="getValue(item)">
    {{ getDisplay(item) }}
  </option>
</select>
